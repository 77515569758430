const imageFragment = `
image {
  asset->{
    ...,
    metadata {
      lqip
    }
  }
}
`;

const responsiveImageFragment = `
desktopImage {
  ${imageFragment}
},
mobileImage {
  ${imageFragment}
}
`;

const ctaFragment = `
...,
cta{
  ...,
  "slug": @.target->slug
}
`;

const FAQFragment = `
_type == 'FAQs' => {
  ...,
  faqs[]{
    ...,
    reference->{
      ...
    }
  }
}
`;

const CheckoutFAQFragment = `
_type == 'CheckoutFAQ' => {
  ...,
  "faqs": *[_type == "faqs" && length(purchases) > 0]
}
`;

const TestimonialFragment = `
_type == 'TestimonialGrid' => {
  "testimonials": testimonials[]{
    ...,
    testimonial->{
      ...
    }
  }
}
`;

const TestimonialMarqueeCuratedFragment = `
_type == 'TestimonialMarqueeCurated' => {
  "testimonials": testimonials[]{
    ...,
    testimonial->{
      ...
    }
  }
}
`;

const DeviceGallery = `
_type == 'DeviceGallery' => {
  ...,
  blocks[]{
    ...,
    "slug": @.target->slug.current
  }
}
`;

const TestimonialMarquee = `
_type == 'TestimonialMarquee' => {
  "testimonials": *[_type == "testimonials" && length(review) < ^.maxLength && length(review) > ^.minLength && length(user) < ^.userLength] | order(_updatedAt desc)[0..40]
}
`;

const TestimonialRows = `
_type == 'TestimonialRows' => {
  "testimonials": testimonials[]{
    ...,
    testimonial->{
      ...
    }
  }
}
`;

const HeroCurvedFragment = `
_type == 'HeroCurved' => {
  ${ctaFragment}
}
`;
const HeroPlainFragment = `
_type == 'HeroPlain' => {
  ${ctaFragment}
}
`;
const HeroRoundImageFragment = `
_type == 'HeroRoundImage' => {
  ${ctaFragment}
}
`;
const MediaOverlapFragment = `
_type == 'MediaOverlap' => {
  ${ctaFragment}
}
`;
const ShareFragment = `
_type == 'Share' => {
  ${ctaFragment}
}
`;
const TextOnImageFragment = `
_type == 'TextOnImage' => {
  ${ctaFragment}
}
`;
const DeviceSideLayoutFragment = `
_type == 'DeviceSideLayout' => {
  ${ctaFragment}
}
`;
const DeviceGalleryNewFragment = `
_type == 'DeviceGalleryNew' => {
  ...,
  blocks[]{
    ${ctaFragment}
  }
}
`;
const GalleryFragment = `
_type == 'Gallery' => {
  ...,
  blocks[]{
    ...,
    "slug": @.target->slug.current
  }
}
`;
const ContentFeatureFragment = `
_type == 'ContentFeature' => {
  ...,
  image {
    ${imageFragment}
  },
  content{
    ${ctaFragment}
  }
}
`;
const PlanPicker = `
_type == 'PlanPicker' => {
  ...,
  "thankYouPage": @.thankYouPage->slug
}
`;
const PurchasePicker = `
_type == 'PurchasePicker' => {
  ...,
  "thankYouPage": @.thankYouPage->slug,
  "products": products[]{
    ...,
    "learnMorePage": @.learnMore->slug
  }
}
`;
const DeviceTestimonialsFragment = `
_type == 'DeviceTestimonials' => {
  ...,
  "testimonials": testimonials[]{
    ...,
    testimonial->{
      ...
    }
  }
}
`;

export const articleBodyFragment = `
"bodyLength": length(pt::text(body)),
"bodySections": body[]{
  _type,
  "richTitleLength": length(pt::text(title)),
  "titleLength": length(title),
  "paragraphLength": length(paragraph),
  "richParagraphLength": length(pt::text(paragraph)),
  _type == 'Paragraphs' => {
    blocks[]{
      "paragraphLength": length(pt::text(paragraph)),
      "titleLength": length(title)
    }
  },
  _type == 'ImageSide' => {
    "paragraphLength": length(pt::text(content.paragraph)),
     "titleLength": length(content.title)
  }
}
`;

const ArticleFeed = `
_type == 'ArticleFeed' => {
  ...,
  "articles": *[_type == "articles" && hideFromLists != true] | order(_updatedAt desc)[0..5]{
    title,
    author,
    "slug": slug.current,
    producer->{
      ...
    },
    ${articleBodyFragment}
  }
}
`;

const ArticleFeedarticleFragment = `
_type == 'ArticleFeed' => {
  ...,
  "articles": *[_type == "articles" && slug.current match $hubSlug && slug.current != $slug] | order(_updatedAt desc)[0..5]{
      title,
      author,
      "slug": slug.current, 
      ${articleBodyFragment}
    }
  }
`;

const HubGrid = `
_type == 'HubGrid' => {
  ...,
  "hubs": *[_type == "pages" && type == "hub"][0..5]{
    title,
    topic,
    slug,
    hubImage,
    noHub,
  }
}
`;

const HubGridarticleFragment = `
_type == 'HubGrid' => {
  ...,
  "hubs": *[_type == "pages" && type == "hub" && slug.current != $hubSlug][0..5]{
    title,
    topic,
    slug,
    hubImage,
    noHub,
  }
}
`;

const HubMarquee = `
_type == 'HubMarquee' => {
  ...,
  "hubs": *[_type == "pages" && type == "hub"]{
    title,
    slug,
    noHub,
  }
}
`;

const ResourcesFragment = `
_type == 'Resources' => {
  ...,
  "resources": resources[]{
    ...,
    type == "reference" => {
      "target": @.target->{
        slug,
        title,
      }
    },
  }
}
`;

const ParagraphsFragment = `
_type == 'Paragraphs' => {
  ...,
  blocks[]{
    ${ctaFragment}
  }
}
`;

const ArticleReelFragment = `
_type == 'ArticleReel' => {
  "articles": *[_type == "articles" && slug.current match $slug && !(_id in path("drafts.**"))] | order(_updatedAt desc)[0..20] {
    ...,
    ${articleBodyFragment}
  }
}
`;

// Query differently when used on article pages.
const ArticleReelarticleFragment = `
_type == 'ArticleReel' => {
  "articles": *[_type == "articles" && slug.current match $hubSlug && slug.current != $slug && !(_id in path("drafts.**"))] | order(_updatedAt desc)[0..20]{
    ...,
    ${articleBodyFragment}
  }
}
`;


const QuoteFragment = `
_type == 'Quote' => {
  ...,
  backgroundImage {
    ${responsiveImageFragment}
  }
}
`;

export const staticSectionsFragment = `
"sections": sections[]{
  ...,
  ${DeviceSideLayoutFragment},
  ${HeroCurvedFragment},
  ${DeviceGalleryNewFragment},
  ${DeviceGallery},
  ${GalleryFragment},
  ${HeroPlainFragment},
  ${HeroRoundImageFragment},
  ${ParagraphsFragment},
  ${TestimonialFragment},
  ${MediaOverlapFragment},
  ${ShareFragment},
  ${TextOnImageFragment},
  ${FAQFragment},
  ${ContentFeatureFragment},
  ${DeviceTestimonialsFragment},
  ${CheckoutFAQFragment},
  ${TestimonialRows},
}
`;

const allSections = `
  ${DeviceSideLayoutFragment},
  ${HeroCurvedFragment},
  ${HeroPlainFragment},
  ${DeviceGalleryNewFragment},
  ${DeviceGallery},
  ${GalleryFragment},
  ${HeroRoundImageFragment},
  ${ParagraphsFragment},
  ${TestimonialFragment},
  ${MediaOverlapFragment},
  ${ShareFragment},
  ${TextOnImageFragment},
  ${FAQFragment},
  ${ContentFeatureFragment},
  ${PlanPicker},
  ${PurchasePicker},
  ${ResourcesFragment},
  ${ArticleFeed},
  ${ArticleReelFragment},
  ${HubGrid},
  ${QuoteFragment},
  ${HubMarquee},
  ${TestimonialMarquee},
  ${TestimonialMarqueeCuratedFragment},
  ${TestimonialRows},
  ${DeviceTestimonialsFragment}
`;

const allArticleSections = `
${HeroCurvedFragment},
${HeroRoundImageFragment},
${HeroPlainFragment},
${DeviceSideLayoutFragment},
${GalleryFragment},
${DeviceGalleryNewFragment},
${DeviceGallery},
${ParagraphsFragment},
${TextOnImageFragment},
${ContentFeatureFragment},
${MediaOverlapFragment},
${TestimonialFragment},
${ShareFragment},
${FAQFragment},
${PlanPicker},

${ArticleFeedarticleFragment},
${ArticleReelarticleFragment},
${HubGridarticleFragment},
${HubMarquee},
${TestimonialMarquee},
${TestimonialMarqueeCuratedFragment},
${DeviceTestimonialsFragment}
`;

const sectionsFragment = `
"sections": sections[]{
  ...,
  ${allSections}
}
`;

const articleSectionsFragment = `
"body": body[]{
  ...,
  ${allArticleSections}
}
`;

const mobileArticleSectionsFragment = `
"body": body[]{
  ...,
  ${DeviceSideLayoutFragment},
  ${GalleryFragment},
  ${DeviceGalleryNewFragment},
  ${DeviceGallery},
  ${ParagraphsFragment},
  ${TextOnImageFragment},
  ${ContentFeatureFragment},
  ${MediaOverlapFragment},
  ${TestimonialFragment}
}
`;

const actionFragment = `
action{
  ...,
  "thankYouPage": @.thankYouPage->slug
}
`;
const headerFragment = `
header{
  ...,
  announcement{
    ...,
    "slug": @.target->slug.current
  }
}
`;

export const pageFragment = `
*[_type == "pages" && slug.current == $slug] | order(_updatedAt desc)[0]{
  ...,
  ${headerFragment},
  ${actionFragment},
  ${sectionsFragment}
}
`;
export const hubFragment = `
*[_type == "pages" && slug.current == $hubSlug] | order(_updatedAt desc)[0]{
  ...,
  ${headerFragment},
  ${actionFragment},
  ${sectionsFragment}
}
`;


export const articleFragment = `*[_type == "articles" && slug.current == $slug] | order(_updatedAt desc)[0]{
  ...,
  image {
    ${imageFragment}
  },
  producer->{
    ...
  },
  ${actionFragment},
  ${articleSectionsFragment}
}`;

export const mobileArticleFragment = `*[_type == "articles" && slug.current == $slug] | order(_updatedAt desc)[0]{
  ...,
  producer->{
    ...
  },
  ${mobileArticleSectionsFragment}
}`;

export const articlesFragment = `*[_type == "articles"] | order(_updatedAt desc)[$from...$to]{
  ...,
  producer->{
    ...
  },
  ${articleBodyFragment}
}`;

export const globalSettingsFragment = `
*[_type == "siteSettings"][0]{
  ...,
  announcement{
    ...,
    "slug": @.target->slug.current
  },
  upsellModal{
    ...,
    ${staticSectionsFragment}
  }
}
`;

export const navigationFragment = `
*[_type == "navigation"] | order(_updatedAt desc)[0]{
  ...,
  linklist[]{
    ...,
    "target": @.link.target->{
      slug,
      title,
      noHub,
    },
    articles[]{
      "article": @.article->{
        title,
        slug
      }
    },
    gallery[]{
      ...,
      "target": @.target->{
        slug,
        image,
        hubImage,
        noHub,
        title,
        "topicImage": topic.coverImage.url
      }
    }
  }
}
`;

export const footerNavigationFragment = `
*[_type == "footerNavigation"] | order(_updatedAt desc)[0]{
  ...,
  linklist[]{
    ...,
    "slug": @.target->slug,
    links[]{
      ...,
      type == "reference" => {
        "target": @.target->{
          slug,
          title,
        }
      },
    }
  }
}
`;

export const sideNavigationFragment = `
*[_type == "sideNavigation"] | order(_updatedAt desc)[0]{
  ...,
  linklist[]{
    ...,
    "slug": @.target->slug,
  }
}
`;

export const templateFragment = `
*[_type == $type][0]{
  ...,
  ${staticSectionsFragment}
}
`;
